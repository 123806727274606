import React from 'react';

function FAQ() {
  return (
    <section id="FAQ">
      <h2>FAQ Us</h2>
      <p>This is the FAQ section.</p>
    </section>
  );
}

export default FAQ;
